function setLozad() {
  const observer = lozad('.lozad', {
    rootMargin: '200px 0px',
    loaded: function(el) {
      let gaData = {
        'items': [{
          'item_id': el.dataset.targetId,
          'index': parseInt(el.dataset.rank),
        }],
        'item_list_name': el.dataset.category,
      };
      gtag('event', 'view_item_list', gaData);
      }
    }
  );
  observer.observe();
}

$(function(){
  if (typeof lozad != 'undefined') {
    setLozad();
  } else {
    var checkExist = setInterval(function() {
      if (typeof lozad != 'undefined') {
        clearInterval(checkExist);
        setLozad();
      }
    }, 150);
  }

  $(".click-track").on('click', function(e) {
    var target = $(this)[0];
    var data = {
      path: gm_path,
      id: gm_id,
      href: target.href,
      category: target.dataset.category,
    };
    let gaData = {
      'items': [{
        'item_id': target.dataset.targetId,
        'index': parseInt(target.dataset.rank),
      }],
      'item_list_name': target.dataset.category,
    };
    gtag('event', 'select_item', gaData);
  });
});
